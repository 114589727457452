import { useEffect } from "react";

const faqURL =
  "https://general-playroom-cfc.notion.site/c0cd4ef4ffa34fd590cf323c05f73aac?v=3bd3f3f4464d470bb04dae0ef5737d5f";

const FAQ = () => {
  useEffect(() => {
    window.location.href = faqURL;
  }, []);

  return null;
};

export default FAQ;
